
export default {
    props : [
        'field'
    ],
    data() {
        return {
            value: null,
            focused : false,
            showPsw: false,
            inited: false,
            evalText(s) {
                try{
                    s = eval(s);
                }catch(err){
                    s = s;
                }
                return s;
            },
            getName(){
                return this.field.name ? this.field.name : null
            }
        }
    },
    computed : {
        placeholder(){
            var p  = this.field.placeholder ? this.$__(this.field.placeholder) : null;
            if(!p) return null;
            return p;
        },
        label(){
            return this.field.label ? this.$__(this.field.label) : null;
        },
        valueField(){
            return this.field.value
        }
    },
    watch : {
        focused(t){
            if(!t) this.valueChanged();
        },
        value(){
            if(this.inited || this.field.forceValueChange) this.valueChanged(this.value);
        },
        valueField(t){
            if(t != this.value) this.value = t;
        }
    },
    created(){
        this.value = this.field.value;
        this.$nextTick(() => {
            this.inited = true;
        })
    },
    mounted(){
        if(this.field && this.field.value) this.value = this.field.value;
        this.$parent.$on('evalField', this.valueChanged);
        this.$parent.$on('emptyField', this.emptyField);

        this.$parent.$on('updateFields', () => { this.$forceUpdate(); });
    },
    methods :{
        emptyField(){
            this.inited = false;
            var field = JSON.parse(JSON.stringify(this.field));
            field.value = null
            this.value = null;
            this.$emit('input', field);
            this.$parent.$forceUpdate();
            this.$nextTick(() => {
                this.inited = true;
            })
        },
        valueChanged(value){
            var field = JSON.parse(JSON.stringify(this.field));
            if(value || value == '') field.value = value
            field = this.evalField(field);
            this.$emit('input', field);

            this.$parent.$forceUpdate();
        },
        evalField(field){
            if(field.type != 'testo'){
                if(field.required || field.typeof){
                    field.error = this.$testEmpty(field.value);
                    if(!field.error){
                        switch(field.type){
                            case 'email':
                                if(!this.focused) field.error = !this.$testEmail(field.value);
                            break;
                            case 'select':
                            case 'checkbox':
                            case 'privacy':
                            case 'text': 
                                field.error = !field.value;
                                break;
                            default:
                                break;
                        }
                    }
                }
            }
            return field;
        },
    },
    beforeDestroy() {
        this.$parent.$off('evalField');
        this.$parent.$off('updateFields');
        this.$parent.$off('emptyField');
    },
}
